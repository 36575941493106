<template>
  <PageWrapper
    ><div class="w-full">
      <Header v-slot="{ _headerClass, _subheaderClass }">
        <h1 :class="_headerClass">Address</h1>
        <p :class="_subheaderClass">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi
          fugit enim facere aliquid, vitae sapiente distinctio voluptatem ut.
          Quae, consequuntur!
        </p>
      </Header>

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-4">
        <TextField
          :label="'Address'"
          :placeholder="'Enter Address'"
          :modelValue="address1"
          @textChange="setAddress1"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
        <TextField
          :label="'Unit'"
          :placeholder="'Enter Unit'"
          :modelValue="address2"
          @textChange="setAddress2"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
        <TextField
          :label="'City'"
          :placeholder="'Enter City'"
          :modelValue="city"
          @textChange="setCity"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
        <Select
          :label="'State'"
          :placeholder="'Select State'"
          :options="stateOptions"
          :required="true"
          :modelValue="residencyState"
          @select="setResidencyState"
          labelClasses="font-medium text-xs mb-2"
          selectClasses="bg-gray-100 py-2 pl-4 pr-2 border border-gray-300 rounded flex justify-between items-center"
          focusClasses="bg-gray-100 border border-primary rounded"
        />
        <TextField
          :label="'Zip'"
          :placeholder="'Enter Zip'"
          :pattern="'[0-9]*'"
          :modelValue="zipCode"
          @textChange="setZipCode"
          labelClasses="font-medium text-xs mb-2"
          inputClasses="bg-gray-100 py-2 px-4 border border-gray-300 rounded"
          focusClasses="bg-gray-100 py-2 px-4 border border-primary rounded"
        />
      </div>
      <div class="w-full flex justify-end items-center">
        <router-link
          :to="{ name: 'contact' }"
          class="btn-secondary py-2 w-24 rounded text-sm mx-2"
        >
          Back</router-link
        ><router-link
          :to="{ name: 'household' }"
          class="btn-primary text-sm py-2 w-24 rounded"
          >Continue</router-link
        >
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import { stateOptions } from "../../common/constants";
import useBenefitRecord from "../../composables/useBenefitRecord";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import TextField from "../../components/common/ui/TextField.vue";
import Select from "../../components/common/ui/Select.vue";
import Header from "../../components/home/ui/Header.vue";
export default {
  components: {
    PageWrapper,
    TextField,
    Select,
    Header,
  },
  setup() {
    const {
      address1,
      address2,
      city,
      residencyState,
      zipCode,
      setAddress1,
      setAddress2,
      setCity,
      setResidencyState,
      setZipCode,
    } = useBenefitRecord();

    return {
      stateOptions,
      address1,
      address2,
      city,
      residencyState,
      zipCode,
      setAddress1,
      setAddress2,
      setCity,
      setResidencyState,
      setZipCode,
    };
  },
};
</script>
